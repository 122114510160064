<script setup lang="ts">
const props = defineProps({
  property: {},
})
const formatNumber = (num) => {
  const format = (value, unit) => {
    // Check if the number is a whole number (e.g., 5.0)
    return value % 1 === 0 ? value + unit : value.toFixed(1) + unit
  }

  if (num >= 1_000_000_000) {
    return format(num / 1_000_000_000, " M") // Billion
  } else if (num >= 1_000_000) {
    return format(num / 1_000_000, " Jt") // Million
  } else if (num >= 1_000) {
    return format(num / 1_000, " Ribu") // Thousand
  } else {
    return num.toString() // For numbers less than 1,000
  }
}
</script>
<template>
  <div class="flex flex-col border rounded-xl overflow-clip shadow-lg h-full">
    <div class="h-56 overflow-clip">
      <!-- {{ property.pic_url }} -->
      <NuxtLink :to="`/property/${property.id}`">
        <img
          class="w-full h-full object-cover"
          :src="property.gambar_utama"
          alt=""
        />
      </NuxtLink>
    </div>
    <div class="flex flex-col flex-1">
      <!-- property data -->
      <div class="py-2 px-3 flex justify-between items-center mt-1">
        <button
          class="rounded-full border border-brand px-4 py-1 font-bold text-brand inline text-xs"
        >
          {{ property.kategori }}
          <!-- {{ property }} -->
        </button>
        <div class="flex text-md font-semibold text-slate-500">
          <p>Rp.</p>
          <p>{{ formatNumber(property.harga) }}</p>
        </div>
      </div>
      <div
        class="mt-2 py-3 px-4 border-b-2 border-t-2 border-slate-200 flex-1"
      >
        <NuxtLink :to="`/property/${property.id}`">
          <h2
            class="text-slate-500 text-[1rem] font-semibold mb-2 leading-snug line-clamp-1"
          >
            {{ property.tagline }}
          </h2>
        </NuxtLink>
        <div>
          <p class="text-slate-400 text-sm line-clamp-2">
            {{ property.description || "Description not available" }}
          </p>
        </div>
      </div>
      <!-- agent data  -->
      <div class="py-2 px-3 flex-col">
        <NuxtLink :to="`/property/${property.id}`">
          <div class="py-2 flex justify-center">
            <button class="btn">Lihat Property</button>
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
