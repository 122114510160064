<script setup>
const { data: properties } = await useFetchProperties("", "dijual")
</script>

<template>
  <!-- section hero -->
  <section>
    <div class="container mx-auto rounded-xl mt-6 overflow-clip">
      <img
        class="object-cover object-center max-h-96 w-full"
        src="/assets/img/house_sample.jpeg"
        alt=""
      />
    </div>
  </section>
  <!-- section search and filter -->
    <SearchBar />
    
  <!-- List items featured  -->
   <div class="container mx-auto mt-10 ">
     <div>
         <h2 class="text-lg sm:text-3xl font-bold text-brand">Property Pilihan</h2>
         <p class="text-slate-500 mt-3">Cek rekomendasi properti terbaik dari kami serta informasi terlengkapnya.</p>
       </div>
     <div class="container mx-auto  py-8 grid sm:grid-cols-4 gap-6">
       <!-- property-card-big -->
       <div v-for="property in properties.data.slice(0, 4)" :key="property.id">
         <CardFeatured :property="property" />
       </div>
     </div>
   </div>
   <!-- section ads -->
   <div class="container mx-auto w-full flex items-center justify-center">
    <img class="rounded-lg" src="/assets/img/banner-01.jpg" alt="banner">
   </div>

  <!-- List items -->
  <div class="container mx-auto py-10 grid sm:grid-cols-2 md:grid-cols-4 gap-6">
    <!-- property-card -->
    <div v-for="property in properties.data">
      <Item :property="property" />
    </div>
  </div>
</template>

<style scoped></style>
