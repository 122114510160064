<script setup>

import { ref, onMounted, onUnmounted } from 'vue';

onMounted(() => {
  // Make sure the code only runs in the browser environment
  if (typeof document !== 'undefined') {
    const stickyElement = document.getElementById("stickyElement");
    const searchBarHeader = document.getElementById("searchBarHeader")

    if (stickyElement) {
      // Function to toggle width based on scroll position
      const toggleWidth = () => {
        if (stickyElement.getBoundingClientRect().top <= 0) {
          stickyElement.classList.add("sm:w-full", "w-full",);
          searchBarHeader.classList.add("hidden");
        } else {
          stickyElement.classList.remove("sm:w-full", "w-full");
          searchBarHeader.classList.remove("hidden");
        }
      };

      // Listen for scroll events
      window.addEventListener("scroll", toggleWidth);

      // Optional: Cleanup event listener when component is unmounted
      onUnmounted(() => {
        window.removeEventListener("scroll", toggleWidth);
      });
    }
  }
});

const searchValue = ref("");
const dijual = ref(true);
const showToast = ref(false);
const toastMessage = ref("");

const route = useRoute()

if (route.query.q != undefined) {
  searchValue.value = route.query.q
}
if (route.query.dijual != undefined) {
  if (route.query.dijual == "false") {
    dijual.value = false
  }
}

const cari = async () => {
  if (!searchValue.value.trim()) {
    // show toast message if searchValue is empty
    toastMessage.value = "Masukan dulu pencarian anda";
    showToast.value = true;

    // Hide the toast message after 3sec
    setTimeout(() => showToast.value = false, 3000)
  } else {
    await navigateTo(`/property?q=${searchValue.value}&dijual=${dijual.value}`, {
      replace: false,
    });
  }
};

const tonggleDijual = async () => {
  dijual.value = !dijual.value;
  searchValue.value = "";
};

const showP = ref(false)

</script>
<template>
  <!-- Toast message -->
  <Transition name="toast">
    <div v-if="showToast"
      class="fixed w-[90%] sm:w-1/4 rounded-md mx-auto top-2 left-0 right-0 p-4 bg-red-500 text-white text-center z-50">
      {{ toastMessage }}
    </div>
  </Transition>

  <div id="stickyElement"
    class="mx-auto sticky top-0 my-4 py-4 px-2 bg-slate-100 rounded-2xl shadow-xl w-[95%] sm:w-1/2 z-50">
    <div class="flex flex-col sm:px-4 space-y-2">

      <h1 id="searchBarHeader" class="text-lg sm:text-2xl font-bold text-center text-brand">
        Cari Property Terbaik
      </h1>
      <!-- <div class="flex w-full sm:hidden h-[.1rem] bg-slate-300"></div> -->
      <div class="flex space-x-4 py-2 items-center justify-center">
        <div>
          <button :class="`text-slate-400 focus:outline-none focus:ring-0 focus:border-brand font-bold  py-1 text-sm mx-1 ${dijual ? 'border-b-2 border-brand text-brand' : ''
            }`" @click="tonggleDijual">
            Dijual
          </button>
        </div>
        <div>
          <button :class="`text-slate-400 focus:outline-none focus:ring-0 focus:border-brand py-1 text-sm font-medium mx-1 ${!dijual ? 'border-b-2 border-brand text-brand' : ''
            }`" @click="tonggleDijual">
            Disewa
          </button>
        </div>
      </div>
      <div class="flex space-x-3">
        <input
          class="px-2 py-2 rounded-lg border focus:outline-none focus:ring-0 focus:border-brand text-slate-500 border-slate-200 bg-slate-200 w-1/2 flex-1"
          type="Search" placeholder="Cari" v-model="searchValue" @keyup.enter="cari" />
        <button
          class="text-sm sm:text-base rounded-lg ml-2 px-8 bg-brand text-white focus:outline-none focus:ring-1 focus:border-brand"
          @click="cari">
          Search
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* .toast-enter-from{
  opacity: 0;
  transform: translateY(-60px);

}
.toast-enter-to{
  opacity: 1;
  transform: translateY(0px);
} */
.toast-enter-active {
  /* transition: all 0.3s ease; */
  animation: wobble 0.5s ease;
}

.toast-leave-from {
  opacity: 1;
  transform: translateY(0px);

}

.toast-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}

.toast-leave-active {
  transition: all 0.3s ease;
}

@keyframes wobble {
  0% {
    transform: translateY(-60px);
    opacity: 0
  }

  50% {
    transform: translateY(0px);
    opacity: 1
  }

  60% {
    transform: translateX(8px)
  }

  70% {
    transform: translateX(-8px)
  }

  80% {
    transform: translateX(4px)
  }

  90% {
    transform: translateX(-4px)
  }

  100% {
    transform: translateX(0px)
  }


}
</style>
